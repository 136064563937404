<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Client Question List</h2>
    </template>
    <template #action-bar>
      <div class="level">
        <div class="level-left"></div>
        <div class="level-right">
          <b-button icon-left="filter-variant" class="is-command">
            Filter
          </b-button>
          <b-input icon-right="magnify" rounded v-model="search">
            Search
          </b-input>
        </div>
      </div>
    </template>
    <template #page-content>
      <r-card>
        <b-table
          :data="data ? data : []"
          aria-next-label="Next page"
          :loading="isLoading"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          backend-sorting
          @sort="onSort"
          :current-page="page"
          hoverable
        >
          <b-table-column field="no" label="No" v-slot="props">
            {{ props.index + 1 }}
          </b-table-column>
          <b-table-column
            field="full_name"
            sortable
            label="Name"
            v-slot="props"
          >
            {{ props.row.fullName }}
          </b-table-column>
          <b-table-column
            field="company.name"
            sortable
            label="Company Name"
            v-slot="props"
          >
            {{ props.row.companyUser.company.name }}
          </b-table-column>

          <b-table-column
            field="phone_number"
            sortable
            label="Phone Number"
            v-slot="props"
          >
            {{ props.row.phoneNumber }}
          </b-table-column>

          <b-table-column
            field="user.email"
            sortable
            label="Email"
            v-slot="props"
          >
            {{ props.row.companyUser.user.email }}
          </b-table-column>

          <b-table-column
            field="message"
            sortable
            label="message"
            v-slot="props"
          >
            {{ props.row.message }}
          </b-table-column>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  No data yet, please contact us if you have any problems
                  (hello@getrise.id)
                </p>
              </div>
            </section>
          </template>
        </b-table>
      </r-card>
      <router-view />
    </template>
  </r-page>
</template>
<script>
export default {
  data() {
    return {
      data: {
        meta: {},
        data: [],
      },
      perPage: 5,
      sortField: 'created_at',
      sortOrder: 'desc',
      total: 0,
      page: 1,
      lastPage: 1,
      search: '',
      isLoading: false,
      delay: (function () {
        let timer = 0
        return function (callback, ms) {
          clearTimeout(timer)
          timer = setTimeout(callback, ms)
        }
      })(),
    }
  },
  created() {
    this.isLoading = true
    this.fetchData(
      this.page,
      this.perPage,
      this.sortField,
      this.sortOrder,
      this.search
    )
    document.addEventListener('scroll', this.infiniteScroll)
  },

  computed: {},
  destroyed() {
    document.removeEventListener('scroll', this.infiniteScroll)
  },
  methods: {
    async fetchData(page, perPage, orderBy, orderDirection, search) {
      await this.$store
        .dispatch('question/getQuestions', {
          page,
          perPage,
          orderBy,
          orderDirection,
          search,
        })
        .then((response) => {
          this.data = response.data.data
          console.log(this.data)
          this.lastPage = response.data.meta.lastPage
        })
        .catch((error) => {
          console.log(error)
        })
      this.isLoading = false
    },
    onSort(field, order) {
      this.data.data = []
      this.page = 1
      this.sortField = field
      this.sortOrder = order
      this.isLoading = true
      this.fetchData(
        this.page,
        this.perPage,
        this.sortField,
        this.sortOrder,
        this.search
      )
    },
    infiniteScroll() {
      this.delay(() => {
        if (this.page < this.lastPage) {
          if (
            window.innerHeight + window.scrollY >=
            document.documentElement.scrollHeight - 20
          ) {
            this.page = this.page + 1
            this.isLoading = true
            this.fetchData(
              this.page,
              this.perPage,
              this.sortField,
              this.sortOrder,
              this.search
            )
          }
        }
      }, 1000)
    },
  },
  watch: {
    search(input) {
      this.data.data = []
      this.search = input
      this.page = 1
      this.isLoading = true
      this.delay(() => {
        this.fetchData(
          this.page,
          this.perPage,
          this.sortField,
          this.sortOrder,
          this.search
        )
      }, 300)
    },
  },
}
</script>
